/* import __COLOCATED_TEMPLATE__ from './dynamic-form.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { Button } from '@repo/design-system-kit';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error
import { buildValidations } from 'ember-cp-validations';
import { isNil } from 'es-toolkit';
import { TrackedObject } from 'tracked-built-ins';

import { VALIDATION_TRIGGER_TYPES } from 'qonto/constants/dynamic-form';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
// @ts-expect-error
import { deepMerge } from 'qonto/utils/deep-merge';
import {
  type EnhancedFieldset,
  type EnhancedFormField,
  extendFieldProperties,
  extendFieldsetProperties,
  type FormData,
  type FormField,
  type FormGroup,
  type FormGroupOptions,
  mergeAdjacentFieldsets,
  resolveDefaultValue,
  transformFieldsToFieldsets,
  type Validator,
} from 'qonto/utils/dynamic-form';
import type {
  WithDefaultValue,
  WithDefaultValues,
} from 'qonto/utils/international-out/requirements';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';
// @ts-expect-error
import { unflatten } from 'qonto/utils/unflatten';
import {
  extractCustomValidators,
  extractFieldsValidators,
  mergeValidators,
  // @ts-expect-error
} from 'qonto/utils/validators';

interface Signature {
  Args: {
    formData: WithDefaultValues<FormGroup>[];
    isDisabled: boolean;
    isFetching?: boolean;
    isFocused?: boolean;
    isLoading: boolean;
    omitOptional?: boolean;
    groupOptions?: FormGroupOptions | null;
    validators?: Record<string, Validator>;
    validationTrigger?: (typeof VALIDATION_TRIGGER_TYPES)[keyof typeof VALIDATION_TRIGGER_TYPES];
    submissionText?: string;
    onError?: (data: { type: string; attribute: string }) => void;
    onRefresh?: (data: { type?: FormGroup['type']; data: FormData }) => void;
    onSubmit?: (data: { type?: FormGroup['type']; data: FormData }) => void;
  };
  Element: HTMLFormElement;
}

export default class TransfersInternationalOutDynamicFormComponent extends Component<Signature> {
  button = Button;

  @service declare intl: Services['intl'];

  @tracked groupType = this.args.formData[0]?.type;

  fieldValuesTracker: Record<string, string | null | undefined> = new TrackedObject();
  fieldValidationStatusTracker: Record<string, boolean | null | undefined> = new TrackedObject();

  isValidationEnabledFor = (fieldKey: FormField['key']): boolean => {
    const fieldValidationStatus =
      this.fieldValidationStatusTracker[this.#getIdentifier(this.#currentGroup?.type, fieldKey)];

    if (!isNil(fieldValidationStatus)) {
      return fieldValidationStatus;
    }

    return !isNil(this.#fields.find(({ key }) => key === fieldKey)?.defaultValue);
  };

  get #allFields(): WithDefaultValue<EnhancedFormField>[] {
    return this.#flattenFields.map(extendFieldProperties);
  }

  get #currentGroup(): WithDefaultValues<FormGroup> | null {
    return this.args.formData?.find(formDataGroup => formDataGroup.type === this.groupType) ?? null;
  }

  get #fields(): WithDefaultValue<EnhancedFormField>[] {
    return this.#omitOptional ? this.#requiredFields : this.#allFields;
  }

  get fieldsets(): EnhancedFieldset<WithDefaultValue<EnhancedFormField>>[] {
    const fieldsets = transformFieldsToFieldsets(this.#fields);
    const mergedFieldsets = mergeAdjacentFieldsets(fieldsets);
    return mergedFieldsets.map(extendFieldsetProperties);
  }

  get #flattenFields(): WithDefaultValue<FormField>[] {
    return this.#currentGroup?.fields?.map(({ group }) => group).flat() || [];
  }

  get formData(): FormData {
    return Object.keys(this.formDataModel).reduce((accumulator, key) => {
      if (this.formDataModel.hasOwnProperty(key) && key !== '_super') {
        // @ts-expect-error
        accumulator[key] = this.formDataModel.get(key);
      }
      return accumulator;
    }, {});
  }

  @cached
  get formDataModel() {
    return this.#createFormDataModel({
      fields: this.#fields,
      validators: this.#validators,
    });
  }

  get isFocused(): boolean {
    return this.args.isFocused ?? true;
  }

  get #omitOptional(): boolean {
    return this.args.omitOptional ?? false;
  }

  get #requiredFields(): WithDefaultValue<EnhancedFormField>[] {
    return this.#allFields.filter(({ required }) => required);
  }

  get submissionText(): string {
    return this.args.submissionText ?? this.intl.t('international-out.dynamic-form.submit');
  }

  get #validationTrigger(): (typeof VALIDATION_TRIGGER_TYPES)[keyof typeof VALIDATION_TRIGGER_TYPES] {
    return this.args.validationTrigger ?? VALIDATION_TRIGGER_TYPES.INPUT;
  }

  get #validators(): Record<string, Validator> {
    return this.args.validators ?? {};
  }

  #createDefaultFieldKeyValuesMap<T extends FormField[] = FormField[]>(
    fields: T
  ): Record<string, string | null> {
    return fields.reduce(
      (map, { key, ...field }) => ({
        ...map,
        [key]: resolveDefaultValue(field) ?? null,
      }),
      {}
    );
  }

  // @ts-expect-error
  #createFormDataModel({ fields, validators }) {
    const FormValidationModel = this.#createFormValidationModel({ fields, validators });

    // @ts-expect-error
    class FormDataModel extends EmberObject.extend(FormValidationModel) {
      // @ts-expect-error
      @service intl;
      // @ts-expect-error
      @service localeManager;
    }

    const initialFormData = deepMerge(
      this.#createDefaultFieldKeyValuesMap(fields),
      this.#getPreviousValues(fields)
    );

    // @ts-expect-error
    return FormDataModel.create(getOwner(this).ownerInjection(), {
      ...unflatten(initialFormData),
    });
  }

  // @ts-expect-error
  #createFormValidationModel({ fields, validators }) {
    const fieldsValidators = extractFieldsValidators(fields);
    const customValidators = extractCustomValidators(validators);
    return buildValidations(mergeValidators(fieldsValidators, customValidators));
  }

  #getIdentifier(groupType: FormGroup['type'] = 'default', fieldKey: FormField['key']): string {
    return `${groupType}.${fieldKey}`;
  }

  #getPreviousValues<T extends FormField[] = FormField[]>(
    fields: T
  ): Record<string, string | null | undefined> {
    const currentFormDataGroupKeys = fields.map(({ key }) =>
      this.#getIdentifier(this.#currentGroup?.type, key)
    );

    return Object.fromEntries(
      Object.entries(this.fieldValuesTracker)
        .filter(([key]) => currentFormDataGroupKeys.includes(key))
        .map(([key, value]) => {
          const [, ...destructuredFieldKey] = key.split('.');
          return [destructuredFieldKey.join('.'), value];
        })
    );
  }

  #scrollToError(): void {
    next(() => scrollIntoView('[data-has-error]'));
  }

  #updateFieldValidationStatus({
    fieldKey,
    isValidationEnabled,
  }: {
    fieldKey: FormField['key'];
    isValidationEnabled: boolean;
  }): void {
    this.fieldValidationStatusTracker[this.#getIdentifier(this.#currentGroup?.type, fieldKey)] =
      isValidationEnabled;
  }

  @action
  onClose(fieldKey: FormField['key']): void {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
  }

  @action
  onFocusIn(fieldKey: FormField['key']): void {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: false });
  }

  @action
  onFocusOut(fieldKey: FormField['key']): void {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
  }

  @action
  onRefresh(): void {
    this.args.onRefresh?.({
      type: this.groupType,
      data: this.formData,
    });
  }

  onSubmitTask = dropTask(async (): Promise<void> => {
    const { onError, onSubmit } = this.args;

    const { validations } = await this.formDataModel.validate();

    this.#fields.forEach(({ key: fieldKey }) => {
      this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
    });

    if (validations.isValid) {
      onSubmit?.({
        type: this.groupType,
        data: this.formData,
      });
    } else {
      const { error } = validations;
      onError?.({
        type: error.type,
        attribute: error.attribute,
      });
      this.#scrollToError();
    }
  });

  onUpdateTask = restartableTask(async (fieldKey, value): Promise<void> => {
    this.fieldValuesTracker[this.#getIdentifier(this.#currentGroup?.type, fieldKey)] = isEmpty(
      value
    )
      ? null
      : value;

    await timeout(DEBOUNCE_MS);

    if (this.#validationTrigger === VALIDATION_TRIGGER_TYPES.INPUT) {
      this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm': typeof TransfersInternationalOutDynamicFormComponent;
  }
}
