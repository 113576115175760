/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action, get } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';

import { DOCUMENT_TYPE } from 'qonto/constants/attachments';
// @ts-expect-error
import { NO_PERIOD_ID } from 'qonto/constants/budget';
import { OPERATION_TYPES, STATUS } from 'qonto/constants/transfers';
import { CashflowCategoryManager } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
import { TransactionsSidebarFooter } from 'qonto/react/components/transactions/sidebar/footer';
import { ProcessingEventsTimeline } from 'qonto/react/components/transfers/international-out/processing-events-timeline';
// @ts-expect-error
import periodOptions from 'qonto/routes/transactions/index/period-options';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { isTaskCancelation } from 'qonto/utils/ignore-error';

interface TransactionsSidebarSignature {
  // The arguments accepted by the component
  Args: {
    highlightedItem: any;
    showSuggestedAttachments?: boolean;
    handleShowSuggestedAttachments?: () => void;
    saveLabel?: (labelList: any, label: any, source: string) => void;
    handleDisplayTooltip?: () => void;
    hideTooltip?: () => void;
    isLoadingRelatedData?: boolean;
    isErrorLoadingRelatedData?: boolean;
    isBudgetSupervisor?: boolean;
    isInvoiceTooltipDismissed?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransactionsSidebarComponent extends Component<TransactionsSidebarSignature> {
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare categorizationRulesManager: Services['categorizationRulesManager'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare supplierInvoicesUploadManager: Services['supplierInvoicesUploadManager'];
  @service declare receivableInvoicesUploadManager: Services['receivableInvoicesUploadManager'];
  @service declare attachmentsManager: Services['attachmentsManager'];
  @service declare router: Services['router'];
  @service declare bannerFlashMessages: Services['bannerFlashMessages'];

  // @ts-expect-error
  @tracked timeline;
  @tracked isInvoiceTooltipDismissed = false;
  // @ts-expect-error
  lastHighlightedItemId;
  TransactionsSidebarFooter = TransactionsSidebarFooter;
  CashflowCategoryManager = CashflowCategoryManager;
  ProcessingEventsTimeline = ProcessingEventsTimeline;

  constructor(owner: unknown, args: TransactionsSidebarSignature['Args']) {
    super(owner, args);

    if (this.args.showSuggestedAttachments) {
      // Needed here to avoid an attempt to modify the showSuggestedAttachments property after it has already been used within the same rendering cycle.
      // @ts-expect-error
      next(() => this.args.handleShowSuggestedAttachments());
    }
  }

  get hasTopBanner() {
    // @ts-expect-error
    return this.bannerFlashMessages.isEmpty;
  }

  get shouldShowTimeline() {
    let subject = this.args.highlightedItem.subject;
    let isNotCanceledOrDeclined = ![STATUS.CANCELED, STATUS.DECLINED].includes(
      get(subject, 'status')
    );
    let isInternationalOut = get(subject, 'operationType') === OPERATION_TYPES.INTERNATIONAL_OUT;

    return isNotCanceledOrDeclined && isInternationalOut;
  }

  loadTimelineTask = dropTask(async () => {
    if (!this.shouldShowTimeline) {
      return;
    }

    try {
      this.timeline = await this.internationalOutManager.getTransferTimeline(
        get(this.args.highlightedItem.subject, 'id')
      );
    } catch {
      this.timeline = null;
    }
  });

  get activeCategoryId() {
    return this.categorizationRulesManager.getActiveCategoryId([this.args.highlightedItem]);
  }

  get isLoadingAttachmentSectionElements() {
    return this.fetchAttachmentsTask.isRunning;
  }

  @action
  // @ts-expect-error
  saveLabel(labelList, label, source) {
    // @ts-expect-error
    this.args.saveLabel(labelList, label, source ?? 'transaction_details');
  }

  @action
  trackToggleShowMore() {
    this.segment.track('transaction_analytic_label_expand_clicked', {
      source: 'transaction_details',
    });
  }

  @action
  closeTransactionsSidebar() {
    // @ts-expect-error
    this.args.closeSidebar();
    // @ts-expect-error
    this.args.hideTooltip();
  }

  // @ts-expect-error
  shouldTriggerTask(highlightedItem) {
    if (highlightedItem.id !== this.lastHighlightedItemId) {
      this.lastHighlightedItemId = highlightedItem.id;
      return true;
    }
    return false;
  }

  fetchAttachmentsTask = dropTask(async transaction => {
    await this.store.query('attachment', {
      organization_id: this.organizationManager.organization.id,
      filters: { ids: transaction.attachmentIds },
    });

    let attachmentFiles = transaction.attachments;
    if (attachmentFiles.length > 0 && this.canViewInvoices(transaction)) {
      await this.fetchDocumentTypeTask
        .perform(transaction, attachmentFiles)
        .catch(error => this.handleError(error));
    }
  });

  fetchDocumentTypeTask = dropTask(async (transaction, attachmentFiles) => {
    try {
      let isDebit = transaction.side === 'debit';

      // @ts-expect-error
      let attachmentIds = attachmentFiles.map(attachment => attachment.id).filter(Boolean);

      let invoices = await this.store.query(isDebit ? 'supplier-invoice' : 'receivable-invoice', {
        filter: {
          attachment_id: isDebit ? attachmentIds : attachmentIds.join(','),
        },
      });

      // @ts-expect-error
      let invoiceAttachmentIds = new Set(invoices.map(invoice => invoice.get('attachment.id')));

      // @ts-expect-error
      attachmentFiles.forEach(attachment => {
        if (attachment && attachment.file) {
          let isInvoice = invoiceAttachmentIds.has(attachment.id);

          attachment.set('file', { ...attachment.file, isProcessing: false });
          attachment.set('documentType', isInvoice ? 'invoice' : 'attachment');
        }
      });
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchPeriodOptionsTask = dropTask(async transaction => {
    let transactionId = transaction.id;
    if (this.abilities.can('update transaction budget')) {
      let [allocatablePeriods, allocatedPeriod] = await Promise.all([
        // @ts-expect-error
        this.store.adapterFor('budget').allocatablePeriods({ transactionId }),
        // @ts-expect-error
        this.store.adapterFor('budget').allocatedPeriod(transactionId),
      ]);
      let result = periodOptions(allocatablePeriods, allocatedPeriod);

      if (result.options.length === 0) {
        return {
          options: [
            { name: this.intl.t('team-budgets.allocation.no-budget-available'), disabled: true },
          ],
          selectedOption: null,
        };
      } else {
        result.options.push({
          name: this.intl.t('team-budgets.allocation.no-budget'),
          id: NO_PERIOD_ID,
        });
      }
      return result;
    } else {
      return { options: [], selectedOption: null };
    }
  });

  // @ts-expect-error
  handleError(error) {
    if (!isTaskCancelation(error)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(
          new Error(
            `Transaction related attachments and period options fetch fails with status ${error.status}`,
            { cause: error }
          )
        );
      }
    }
  }

  // @ts-expect-error
  canViewInvoices(transaction) {
    return transaction.side === 'debit'
      ? this.abilities.can('access supplier-invoice') && this.abilities.can('view supplier-invoice')
      : this.abilities.can('read receivable-invoice');
  }

  enrichTransactionTask = task(async highlightedItem => {
    if (!this.shouldTriggerTask(highlightedItem)) {
      return;
    }
    await Promise.all([
      this.fetchAttachmentsTask.perform(highlightedItem).catch(error => this.handleError(error)),
      this.fetchPeriodOptionsTask.perform(highlightedItem).catch(error => this.handleError(error)),
    ]);
  });

  transactionDataTask = trackedTask(this, this.enrichTransactionTask, () => [
    this.args.highlightedItem,
  ]);

  handleSaveAttachmentAsInvoiceError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
  }

  openInInvoicesTask = dropTask(async (id, isDebit) => {
    try {
      let invoices = isDebit ? 'supplier-invoice' : 'receivable-invoice';
      let trackerEvent = isDebit
        ? 'transaction-details_open-in-suppliers-invoices_clicked'
        : 'transaction-details_open-in-client-invoices_clicked';
      this.segment.track(trackerEvent);

      let response = await this.store.query(invoices, {
        filter: {
          attachment_id: id,
        },
      });
      // @ts-expect-error
      this.router.transitionTo(`${invoices}s.show`, response[0].id);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  saveAttachmentAsInvoice = dropTask(async (file, isDebit = true, isAttachmentPreview = false) => {
    let attachment = this.attachmentsManager.getAttachmentByFile(this.args.highlightedItem, file);
    let attachmentId = attachment.id;

    let trackerEvent = isDebit
      ? 'transaction-details_save-in-suppliers-invoices_clicked'
      : 'transaction-details_save-in-clients-invoices_clicked';
    let uploaderService = isDebit
      ? this.supplierInvoicesUploadManager
      : this.receivableInvoicesUploadManager;

    this.segment.track(trackerEvent);

    try {
      // @ts-expect-error
      let result = await uploaderService.createFromAttachmentTask
        .perform(attachmentId)
        .catch(() => {
          this.handleSaveAttachmentAsInvoiceError();
        });

      let supplierInvoiceSuccess = isDebit && result?.attachment_id === attachmentId;
      let clientInvoiceSuccess =
        !isDebit && get(result, 'relationships.attachment.data.id') === attachmentId;

      if (supplierInvoiceSuccess || clientInvoiceSuccess) {
        await attachment.set('documentType', DOCUMENT_TYPE.INVOICE);

        if (isAttachmentPreview) {
          this.toastFlashMessages.toastSuccess(
            this.intl.t(
              isDebit
                ? 'bookkeeping.invoice-preview.actions.save-supplier-invoices.toast.success'
                : 'bookkeeping.invoice-preview.actions.save-client-invoices.toast.success'
            )
          );
        } else {
          this.toastFlashMessages.toastSuccess(
            this.intl.t(
              isDebit
                ? 'transactions.sidebar.attachments.save-supplier-invoices.toast.success'
                : 'transactions.sidebar.attachments.save-client-invoices.toast.success'
            )
          );
        }
      }
    } catch {
      this.handleSaveAttachmentAsInvoiceError();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar': typeof TransactionsSidebarComponent;
  }
}
