export default {
  "row": "qTd",
  "cell": "qTI",
  "empty": "qTt",
  "icon": "qTM",
  "type": "qTP",
  "amount-content": "qTl",
  "cell-approve": "qTX",
  "approve-content": "qTC",
  "row-compact": "qTk"
};
