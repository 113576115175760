/* import __COLOCATED_TEMPLATE__ from './biller-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

// @ts-expect-error
import { PROMOTION_TRACKING_ORIGINS, PROMOTION_TYPES } from 'qonto/constants/promotion';
import { Slug } from 'qonto/react/components/transactions/sidebar/details/slug';

interface TransactionsSidebarBillerDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isLoadingAttachments?: boolean;
    isErrorLoadingAttachments?: boolean;
    isInvoiceTooltipDismissed?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarBillerDetailsComponent extends Component<TransactionsSidebarBillerDetailsSignature> {
  slug = Slug;

  // @ts-expect-error
  @not('args.transaction.declined') hasAccountingValue;
  // @ts-expect-error
  @not('args.transaction.reversed') isNotReversed;
  // @ts-expect-error
  @not('args.transaction.isCreditNote') isNotCreditNote;

  @service declare abilities: Services['abilities'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare segment: Services['segment'];

  get sepaTransferUpsellRecommendation() {
    // @ts-expect-error
    return this.upsellManager.sepaTransferUpsellRecommendation(this.args.transaction);
  }

  get showDisclaimer() {
    return this.hasAccountingValue;
  }
  get showAttachments() {
    return this.isNotCreditNote && this.hasAccountingValue;
  }

  get showBookkeeping() {
    return (
      this.abilities.can('view vat bookkeeping') && this.hasAccountingValue && this.isNotReversed
    );
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }

  get showBudget() {
    // @ts-expect-error
    let { transaction, isBudgetSupervisor } = this.args;

    return (
      this.abilities.can('update transaction budget') &&
      transaction.debit &&
      isBudgetSupervisor &&
      this.hasAccountingValue
    );
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    // @ts-expect-error
    if (this.attachmentModal && !this.attachmentModal.result) {
      // @ts-expect-error
      this.attachmentModal.close();
    }
  }

  @action
  handleOpenDetails() {
    // @ts-expect-error
    this.handleShowAttachment();
  }

  @action
  // @ts-expect-error
  handleShowAttachment(file) {
    // @ts-expect-error
    let selectedFile = file || this.args.transaction.attachments?.[0]?.file;

    // @ts-expect-error
    this.attachmentModal = this.modals.open(
      'attachments/attachment-viewer-modal',
      {
        isFullScreenModal: true,
        hasSidebar: true,
        title: this.modalTitle,
        // @ts-expect-error
        transaction: this.args.transaction,
        selectedFile,
        // @ts-expect-error
        onSaveLabel: (...args) => this.args.saveLabel(...args),
        // @ts-expect-error
        onSelectTag: (...args) => this.args.selectTag(...args),
        // @ts-expect-error
        markAsReviewTask: (...args) => this.args.markAsReviewTask.perform(...args),
        // @ts-expect-error
        saveInInvoices: (...args) => this.args.saveAttachmentAsInvoice(...args),
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  @action
  // @ts-expect-error
  onCtaClick(recommendation) {
    let { recommendedProduct, recommendedRecurrence } = recommendation;

    this.segment.track('transfers_cta_clicked', {
      origin: PROMOTION_TRACKING_ORIGINS.transactionDetails,
      cta_type: PROMOTION_TYPES.UPSELL,
    });
    // @ts-expect-error
    this.subscriptionManager.transitionToSubscriptionOrAddonChange({
      code: recommendedProduct.code,
      recurrence: recommendedRecurrence,
      type: recommendedProduct.type,
    });
  }

  get modalTitle() {
    return this.intl.t('transactions.sidebar.bookkeeping.bookkeeping-details');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::BillerDetails': typeof TransactionsSidebarBillerDetailsComponent;
  }
}
