export default {
  "card": "qHL",
  "card-primary": "qHT",
  "content": "qHz",
  "caption-text": "qHa",
  "content-primary": "qHH",
  "disabled": "qHO",
  "illustration": "qHm",
  "illustration-primary": "qHp",
  "illustration-disabled": "qHx",
  "cta": "qHw",
  "dismiss-button": "qHN",
  "buttons": "qHv",
  "required-badge": "qHo"
};
