export default {
  "header-cell": "qLy",
  "empty": "qLU",
  "header-type": "qLj",
  "header-requester": "qLW",
  "header-amount": "qLu",
  "header-approval": "qTS",
  "header-content": "qTc caption-bold",
  "active": "qTq",
  "row-compact": "qTZ",
  "row-sidebar": "qTR",
  "hidden": "qTQ",
  "animated": "qTe",
  "fadein-item": "qTB",
  "animated-cell": "qTE"
};
