/* import __COLOCATED_TEMPLATE__ from './toggle-basic.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface TransfersSepaPayLaterToggleBasicSignature {
  // The arguments accepted by the component
  Args: {
    isToggleDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersSepaPayLaterToggleBasicComponent extends Component<TransfersSepaPayLaterToggleBasicSignature> {
  @service declare segment: Services['segment'];

  disclaimer = Disclaimer.Inline;

  constructor(owner: unknown, args: TransfersSepaPayLaterToggleBasicSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.args.origin) {
      this.segment.track('pay-later_toggle_displayed', {
        // @ts-expect-error
        origin: this.args.origin,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::PayLater::ToggleBasic': typeof TransfersSepaPayLaterToggleBasicComponent;
  }
}
