export default {
  "transfer-form": "qVf",
  "transfer-form-title": "qVK",
  "transfer-form-subtitle": "qVG",
  "form": "qVr",
  "form-footer": "qVb",
  "footer-btn": "qVy",
  "header": "qVU",
  "main-settings": "qVj",
  "additional-settings": "qVW",
  "form-container": "qVu"
};
