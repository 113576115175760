import { template as template_0104f8afc9214f16b5b09964b7f71352 } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import perform from 'ember-concurrency/helpers/perform';
import { t } from 'ember-intl';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import StickyPanel from 'qonto/components/sticky-panel';
import { DISCLAIMER_TYPES, STATUS, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { Success } from 'qonto/react/components/transfers/sepa/settlement';
import styles from './styles.strict-module.css';
export default class TransfersSepaSettlementRegularTransfer extends Component {
    @service
    intl;
    @service
    router;
    @service
    segment;
    @service
    payByInvoiceUploadManager;
    @service
    organizationManager;
    SuccessComponent = Success;
    constructor(){
        super(...arguments);
        // If a transfer reaches this component, we consider it completed for the flow
        // e.g. it can be 'processing' for scheduled transfers
        this.sepaTransfer.status = STATUS.COMPLETED;
    }
    get sepaTransfer() {
        return this.args.sepaTransfer;
    }
    get isOriginSupplierInvoices() {
        return this.args.context.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES;
    }
    get makeAnotherButtonText() {
        if (this.args.context.isDedicatedFlow) {
            return this.intl.t('transfers.modals.finance-another-btn');
        }
        return this.intl.t('transfers.modals.make-another-btn');
    }
    get hasQontoBeneficiaryWarning() {
        return this.args.context.confirmationResult?.warnings?.includes(DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT);
    }
    static{
        template_0104f8afc9214f16b5b09964b7f71352(`
    <ReactBridgeWithProviders
      @reactComponent={{this.SuccessComponent}}
      @props={{hash
        amount=this.sepaTransfer.amount
        currency=this.sepaTransfer.amountCurrency
        beneficiaryName=this.sepaTransfer.beneficiary.name
        isQontoBeneficiary=this.hasQontoBeneficiaryWarning
        isInstantTransfer=false
        isPending=this.sepaTransfer.isPending
        isScheduled=this.sepaTransfer.wasScheduled
        operationType=this.sepaTransfer.operationType
      }}
    />

    <StickyPanel class={{styles.sticky-panel}}>
      {{! Primary button }}
      {{#if this.isOriginSupplierInvoices}}

        <button
          class='btn btn--primary'
          type='button'
          data-test-back-to-supplier-invoices-button
          {{on 'click' (perform @onClickButtonPrimary 'back-to-supplier-invoices')}}
        >
          {{t 'transfers.modals.to-supplier-invoices-btn'}}
        </button>

      {{else}}

        <button
          class='btn btn--primary'
          type='button'
          data-test-sepa-transfer-make-another-button
          {{on
            'click'
            (perform
              @onClickButtonPrimary 'make-another-transfer' (hash transfer=this.sepaTransfer)
            )
          }}
        >
          {{this.makeAnotherButtonText}}
        </button>

      {{/if}}

      {{! Secondary button }}
      {{#if this.sepaTransfer.isPayLater}}

        <button
          class='btn btn--tertiary'
          type='button'
          data-test-sepa-transfer-go-to-pay-later-section
          {{on 'click' (fn @onClickButtonSecondary 'pay-later')}}
        >
          {{t 'financing.pay-later.application-flow.success.secondary-cta'}}
        </button>

      {{else}}

        <button
          class='btn btn--tertiary'
          type='button'
          data-test-sepa-transfer-close-button
          {{on 'click' (fn @onClickButtonSecondary 'close')}}
        >
          {{t 'btn.close'}}
        </button>

      {{/if}}
    </StickyPanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
