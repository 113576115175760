export default {
  "row": "qTJ body-1",
  "active": "qTs",
  "cell": "qTF",
  "empty": "qTg",
  "ellipsis": "qTD",
  "text-secondary": "qTV",
  "transfer-type": "qTL",
  "transfer-type__icon": "qTT",
  "quick-actions": "qTz",
  "amount": "qTa",
  "cell-approve": "qTH",
  "cell-type": "qTO",
  "cell-requester": "qTm",
  "account-select": "qTp",
  "row-compact": "qTx",
  "row-sidebar": "qTw",
  "cell-amount": "qTN",
  "hidden": "qTv",
  "animated": "qTo",
  "fadein-item": "qTn",
  "animated-cell": "qTi"
};
