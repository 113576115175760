export default {
  "row": "qgw",
  "item-bulk": "qgN",
  "active": "qgv",
  "disabled": "qgo",
  "cell": "qgn body-2",
  "not-disabled": "qgi",
  "item-amount-disabled": "qgA",
  "item-amount-credit": "qgY",
  "no-padding": "qgh",
  "cell-content": "qgf",
  "centered-cell": "qgK",
  "item-counterparty-icon": "qgG mr-16",
  "avatar": "qgr",
  "item-counterparty-name": "qgb",
  "item-counterparty-name-status": "qgy",
  "disputing-chargeback": "qgU",
  "item-counterparty-name-text": "qgj",
  "item-amount": "qgW body-1",
  "item-amount-foreign": "qgu",
  "attachment-icon": "qDS",
  "item-method": "qDc",
  "bank-account-avatar": "qDq",
  "attachment-status-dot": "qDZ",
  "attached": "qDR",
  "missing": "qDQ",
  "item-attachment": "qDe",
  "compact": "qDB",
  "upsell-badge": "qDE",
  "fadein-item": "qDd"
};
