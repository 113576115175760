export default {
  "sidebar-content": "qDf",
  "section": "qDK",
  "header-wrapper": "qDG",
  "transfer-id": "qDr caption",
  "small-padding": "qDb",
  "title": "qDy",
  "subtitle": "qDU",
  "actions": "qDj"
};
