export default {
  "row": "qDI",
  "col": "qDt",
  "header": "qDM",
  "block": "qDP",
  "col-5": "qDl",
  "col-6": "qDX",
  "col-9": "qDC",
  "col-12": "qDk",
  "hide": "qDJ"
};
