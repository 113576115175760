export default {
  "wrapper": "qDO",
  "title": "qDm",
  "activity-tag": "qDp",
  "select": "qDx",
  "tooltip": "qDw",
  "date-picker": "qDN",
  "internal-notes": "qDv",
  "scheduled": "qDo"
};
