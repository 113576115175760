export default {
  "button-wrapper": "qJN",
  "btn-filter": "qJv",
  "name-wrapper": "qJo",
  "preset-name": "qJn",
  "cancel-placeholder": "qJi",
  "cancel-button": "qJA",
  "dropdown-opened": "qJY",
  "dropdown-closed": "qJh",
  "dropdown-menu": "qJf",
  "loading-item": "qJK",
  "placeholder": "qJG",
  "list-body": "qJr",
  "list-item": "qJb",
  "error-state": "qJy body-2",
  "warning": "qJU",
  "warning-icon": "qJj",
  "footer": "qJW",
  "add-icon": "qJu",
  "empty-state": "qsS"
};
