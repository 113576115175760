export default {
  "beneficiary-details": "qzN",
  "beneficiary-account-types": "qzv",
  "divider": "qzo",
  "label": "qzn",
  "labels": "qzi",
  "error": "qzA",
  "link": "qzY caption",
  "sticky-panel": "qzh"
};
