export default {
  "row": "qzP body-1",
  "active": "qzl",
  "cell": "qzX",
  "empty": "qzC",
  "ellipsis": "qzk",
  "text-secondary": "qzJ",
  "cell-type": "qzs",
  "cell-reason": "qzF",
  "cell-amount": "qzg",
  "row-sidebar": "qzD",
  "animated": "qzV",
  "fadein-item": "qzL",
  "animated-cell": "qzT"
};
