export default {
  "header-cell": "qgL",
  "header-content": "qgT caption-bold",
  "active": "qgz",
  "align-right": "qga",
  "empty": "qgH",
  "align-checkbox": "qgO",
  "col-5": "qgm",
  "col-9": "qgp",
  "emitted-at-header-tag": "qgx"
};
