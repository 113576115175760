/* import __COLOCATED_TEMPLATE__ from './transfer-sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ProcessingEventsTimeline } from 'qonto/react/components/transfers/international-out/processing-events-timeline';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface TransferSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransferSidebarComponent extends Component<TransferSidebarSignature> {
  processingEventsTimeline = ProcessingEventsTimeline;

  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare store: Services['store'];

  // @ts-expect-error
  @tracked timeline;

  constructor(owner: unknown, args: TransferSidebarSignature['Args']) {
    super(owner, args);
    this.checkAndLoadTransferTimeline();
  }

  get transferCanBeEdited() {
    // @ts-expect-error
    let { isStanding, isScheduled, isPending } = this.args.transfer;
    return (isStanding || isScheduled) && isPending;
  }

  get isUserKycSubmittedAndPending() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;
      return kycSubmitted && kycPending;
    }

    return false;
  }

  get isUserInitiator() {
    let { id: userId } = this.organizationManager.membership;
    // @ts-expect-error
    let { id: initiatorId } = this.args.transfer.initiator;

    return userId === initiatorId;
  }

  @action
  checkAndLoadTransferTimeline() {
    if (
      // @ts-expect-error
      ['declined', 'canceled'].includes(this.args.transfer.status) ||
      // @ts-expect-error
      !this.args.transfer.isInternationalOut
    ) {
      return;
    }

    // @ts-expect-error
    this.loadTransferTimelineTask.perform(this.args.transfer).catch(ignoreCancelation);
  }

  get shouldDisplayKycDisclaimer() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;

      return !kycSubmitted || kycPending;
    }

    let initiator = this.findInitiatorMembership();

    if (initiator) {
      return !initiator.kycSubmitted || initiator.kycPending;
    }

    return false;
  }

  findInitiatorMembership() {
    let initiatorMembership = this.store.peekAll('membership').filter(({ id }) => {
      // @ts-expect-error
      return this.args.transfer.initiator.id === id;
    });

    if (initiatorMembership.length > 0) {
      let [initiator] = initiatorMembership;
      return initiator;
    }
    return null;
  }

  loadTransferTimelineTask = dropTask(async ({ id }: { id: string }) => {
    try {
      this.timeline = await this.internationalOutManager.getTransferTimeline(id);
    } catch {
      this.timeline = null;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TransferSidebar: typeof TransferSidebarComponent;
  }
}
