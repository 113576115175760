/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SearchFieldWithDebounce, ToggleButton } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { TRANSACTIONS_FILTERS_INSTRUCTIONAL_TOOLTIP_LOCAL_STORAGE_KEY } from 'qonto/constants/transactions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { ColumnManagerTrigger } from 'qonto/react/components/table-v2/column-manager/trigger';

interface TransactionsFiltersSignature {
  // The arguments accepted by the component
  Args: {
    isFetchingTransactions?: boolean;
    isEmptyState?: boolean;
    modularTableToggleState?: boolean;
    onToggleModularTable?: (value: boolean | string) => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransactionsFiltersComponent extends Component<TransactionsFiltersSignature> {
  @service declare bannerFlashMessages: Services['bannerFlashMessages'];
  @service declare segment: Services['segment'];
  // @ts-expect-error
  @tracked activeFilterId;
  // @ts-expect-error
  @tracked selectedPreset = this.args.selectedPreset;
  @tracked cachedFormData = [];
  @tracked isInstructionalTooltipDismissed = JSON.parse(
    safeLocalStorage.getItem(TRANSACTIONS_FILTERS_INSTRUCTIONAL_TOOLTIP_LOCAL_STORAGE_KEY) ??
      'false'
  );

  searchField = SearchFieldWithDebounce;
  toggleButton = ToggleButton;
  instructionalTooltip = InstructionalTooltip;
  columnManagerTrigger = ColumnManagerTrigger;

  @action
  // @ts-expect-error
  onSelectPreset(preset, id) {
    this.selectedPreset = preset;
    this.updateActiveFilterId(id);

    this.cachedFormData = [];
  }

  @action
  // @ts-expect-error
  applyFilters(query, id) {
    this.selectedPreset = undefined;
    this.updateActiveFilterId(id);

    // @ts-expect-error
    this.args.applyFilters(query);
  }

  @action
  clearFilters() {
    this.updateActiveFilterId();
    this.selectedPreset = undefined;
    this.cachedFormData = [];

    // @ts-expect-error
    this.args.applyFilters();
  }

  @action
  // @ts-expect-error
  refreshCache(formData, isUpdating = false) {
    let filteredCache = this.cachedFormData.filter(({ id }) => id !== formData.id);
    // @ts-expect-error
    this.cachedFormData = isUpdating ? [...filteredCache, formData] : filteredCache;
  }

  @action
  updateActiveFilterId(id = null) {
    this.activeFilterId = id;
  }

  get showInstructionalTooltip() {
    if (
      !this.args.modularTableToggleState &&
      !this.isInstructionalTooltipDismissed &&
      variation('feature--boolean-modular-tables-full-release')
    ) {
      this.segment.track('discovery_product-instructional-tooltip_displayed', {
        feature: 'modular_tables_awareness',
        source: 'transactions_screen',
      });

      return true;
    }

    return false;
  }

  get hasTopBanner() {
    // @ts-expect-error
    return !this.bannerFlashMessages.isEmpty;
  }

  setTooltipDismissed() {
    this.isInstructionalTooltipDismissed = true;
    safeLocalStorage.setItem(TRANSACTIONS_FILTERS_INSTRUCTIONAL_TOOLTIP_LOCAL_STORAGE_KEY, 'true');

    this.segment.track('discovery_product-instructional-tooltip_closed', {
      feature: 'modular_tables_awareness',
      source: 'transactions_screen',
    });
  }

  @action
  handleDismissInstructionalTooltip() {
    this.setTooltipDismissed();
  }

  @action
  handleToggleModularTable(value: boolean | string) {
    this.args.onToggleModularTable?.(value);

    if (variation('feature--boolean-modular-tables-full-release')) {
      this.setTooltipDismissed();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Filters': typeof TransactionsFiltersComponent;
  }
}
