export default {
  "container": "qsq",
  "item": "qsZ caption-bold",
  "hover": "qsR",
  "active": "qsQ",
  "name-wrapper": "qse",
  "name": "qsB body-2",
  "count": "qsE",
  "actions-placeholder": "qsd",
  "actions": "qsI",
  "show": "qst",
  "action-button": "qsM"
};
