export default {
  "container": "qsH",
  "header": "qsO",
  "title": "qsm caption-bold",
  "search-icon": "qsp",
  "text": "qsx body-2",
  "trigger": "qsw",
  "loading-state": "qsN",
  "dropzone-placeholder-wrapper": "qsv",
  "dropzone-placeholder": "qso",
  "file-loading": "qsn",
  "search-qonto-cta": "qsi",
  "search-qonto-cta-content": "qsA",
  "tooltip-wrapper": "qsY",
  "invoices-tooltip": "qsh"
};
