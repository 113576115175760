export default {
  "sidebar-header": "qFF",
  "sidebar-header__top": "qFg",
  "sidebar-header__body": "qFD",
  "sidebar-header__content": "qFV",
  "sidebar-header__date": "qFL",
  "infobox": "qFT",
  "infobox--link": "qFz",
  "ghost": "qFa",
  "fail": "qFH",
  "r-transaction-disclaimer": "qFO",
  "hold-disclaimer": "qFm",
  "transaction-link": "qFp body-2",
  "icon-link": "qFx"
};
