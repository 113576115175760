export default {
  "modal": "qCK",
  "section": "qCG",
  "header": "qCr",
  "title": "qCb",
  "separator-title": "qCy",
  "icon": "qCU",
  "formats": "qCj",
  "columns": "qCW",
  "column-list": "qCu",
  "draggable-column": "qkS",
  "column-field": "qkc",
  "column-selector": "qkq",
  "column-with-error": "qkZ",
  "handle": "qkR",
  "label-color": "qkQ",
  "disclaimer": "qke"
};
