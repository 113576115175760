import { template as template_47e0beea7766479192a3662b54398986 } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Spinner from '@qonto/ui-kit/components/spinner';
import perform from 'ember-concurrency/helpers/perform';
import { t } from 'ember-intl';
import and from 'ember-truth-helpers/helpers/and';
import or from 'ember-truth-helpers/helpers/or';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import StickyPanel from 'qonto/components/sticky-panel';
import { DISCLAIMER_TYPES, INSTANT_DECLINED_REASONS_RETRY_STATUS, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { Declined, Processing, Success, Timeout } from 'qonto/react/components/transfers/sepa/settlement';
import styles from './styles.strict-module.css';
export default class TransfersSepaSettlementInstantTransfer extends Component {
    @service
    intl;
    ProcessingComponent = Processing;
    TimeoutComponent = Timeout;
    DeclinedComponent = Declined;
    SuccessComponent = Success;
    get instantTransfer() {
        return this.args.instantTransfer;
    }
    get declinedReason() {
        return this.args.declinedReason;
    }
    get isTimeout() {
        return this.args.isTimeout;
    }
    get canBeRetried() {
        let { declinedReason } = this;
        let hasDefinedRetryStatus = INSTANT_DECLINED_REASONS_RETRY_STATUS.hasOwnProperty(declinedReason);
        return hasDefinedRetryStatus ? INSTANT_DECLINED_REASONS_RETRY_STATUS[declinedReason] : true;
    }
    get shouldDisplayBackToSupplierInvoicesButton() {
        let { origin } = this.args.context;
        return ((this.instantTransfer.isCompleted || this.instantTransfer.isDeclined) && origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES);
    }
    get makeAnotherButtonText() {
        return this.args.context.isDedicatedFlow ? this.intl.t('transfers.modals.finance-another-btn') : this.intl.t('transfers.modals.make-another-btn');
    }
    get closeButtonClass() {
        let hasPrimaryButton = this.instantTransfer.isCompleted || this.instantTransfer.isDeclined;
        return hasPrimaryButton ? 'btn btn--tertiary' : 'btn btn--primary';
    }
    get hasQontoBeneficiaryWarning() {
        return this.args.context.confirmationResult?.warnings?.includes(DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT);
    }
    static{
        template_47e0beea7766479192a3662b54398986(`
    {{#if this.isTimeout}}

      <ReactBridgeWithProviders @reactComponent={{this.TimeoutComponent}} />

    {{else if this.instantTransfer.isProcessing}}

      <ReactBridgeWithProviders @reactComponent={{this.ProcessingComponent}} />

    {{else if this.instantTransfer.isDeclined}}

      <ReactBridgeWithProviders
        @reactComponent={{this.DeclinedComponent}}
        @props={{hash reason=this.declinedReason}}
      />

    {{else if this.instantTransfer.isCompleted}}

      <ReactBridgeWithProviders
        @reactComponent={{this.SuccessComponent}}
        @props={{hash
          amount=this.instantTransfer.amount
          currency=this.instantTransfer.amountCurrency
          beneficiaryName=this.instantTransfer.beneficiary.name
          isQontoBeneficiary=this.hasQontoBeneficiaryWarning
          isInstantTransfer=true
          isPending=this.instantTransfer.isPending
          isScheduled=this.instantTransfer.wasScheduled
          operationType=this.instantTransfer.operationType
        }}
      />

    {{/if}}

    <StickyPanel class={{styles.sticky-panel}}>

      {{! Primary button }}
      {{#if (and this.instantTransfer.isDeclined this.canBeRetried)}}

        <button
          class='btn btn--primary'
          type='button'
          data-test-sepa-transfer-retry-button
          {{on
            'click'
            (perform
              @onClickButtonPrimary
              'retry-as-regular'
              (hash confirmTransferTask=@confirmTransferTask transfer=this.instantTransfer)
            )
          }}
        >
          {{t 'transfers.modals.instant.error.declined.try-regular-btn'}}

          {{#if @onClickButtonPrimary.isRunning}}
            <Spinner @size='small' @color='primary-b' class='ml-8' />
          {{/if}}
        </button>

      {{else if this.shouldDisplayBackToSupplierInvoicesButton}}

        <button
          class='btn btn--primary'
          type='button'
          data-test-back-to-supplier-invoices-button
          {{on 'click' (perform @onClickButtonPrimary 'back-to-supplier-invoices')}}
        >
          {{t 'transfers.modals.to-supplier-invoices-btn'}}
        </button>

      {{else if (or this.instantTransfer.isCompleted this.instantTransfer.isDeclined)}}

        <button
          class='btn btn--primary'
          type='button'
          data-test-sepa-transfer-make-another-button
          {{on
            'click'
            (perform
              @onClickButtonPrimary
              'make-another-transfer'
              (hash transfer=this.instantTransfer declinedReason=this.declinedReason)
            )
          }}
        >
          {{this.makeAnotherButtonText}}
        </button>

      {{/if}}

      {{! Secondary button }}
      {{#if this.instantTransfer.isPayLater}}

        <button
          class='btn btn--tertiary'
          type='button'
          data-test-sepa-transfer-go-to-pay-later-section
          {{on 'click' (fn @onClickButtonSecondary 'pay-later')}}
        >
          {{t 'financing.pay-later.application-flow.success.secondary-cta'}}
        </button>

      {{else if (or this.instantTransfer.isDeclined this.isTimeout)}}

        <button
          class={{this.closeButtonClass}}
          type='button'
          data-test-sepa-transfer-close-button
          {{on 'click' (fn @onClickButtonSecondary 'close')}}
        >
          {{t 'btn.close'}}
        </button>

      {{else if this.instantTransfer.isCompleted}}

        <button
          class='btn btn--tertiary'
          type='button'
          data-test-sepa-transfer-details-button
          {{on
            'click'
            (fn @onClickButtonSecondary 'see-transfer-details' (hash transfer=this.instantTransfer))
          }}
        >
          {{t 'transfers.repeat.sepa.new.success.cta'}}
        </button>

      {{/if}}
    </StickyPanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
