export default {
  "container": "qHK",
  "cards-container": "qHG",
  "card": "qHr",
  "expanded": "qHb",
  "hidden": "qHy",
  "collapsed": "qHU",
  "with-expand-collapse-animation": "qHj",
  "with-dismiss-animation": "qHW",
  "has-one-card": "qHu",
  "dismissing": "qOS",
  "with-hover-animation": "qOc",
  "toggle-button": "qOq",
  "toggle-container": "qOZ",
  "toggle-button-icon": "qOR",
  "has-two-cards": "qOQ"
};
