/* import __COLOCATED_TEMPLATE__ from './radio-group.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { RadioButtonControlled, RadioGroup } from '@repo/design-system-kit';

import type {
  FormFieldArgs,
  FormFieldWithOptions,
} from 'qonto/components/transfers/international-out/dynamic-form/field/arguments';
import isFunction from 'qonto/utils/is-function';

interface Signature {
  Args: FormFieldArgs<FormFieldWithOptions>;
  Element: HTMLFieldSetElement;
}

export default class TransfersInternationalOutDynamicFormFieldRadioGroupComponent extends Component<Signature> {
  radioButtonControlled = RadioButtonControlled;
  radioGroup = RadioGroup;

  get selectedOption(): FormFieldWithOptions['valuesAllowed'][number] | null {
    const { field, value } = this.args;
    return field.valuesAllowed.find(option => option.key === value) ?? null;
  }

  get #shouldRefreshOnChange(): boolean {
    const { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onSelect(selectedOption: FormFieldWithOptions['valuesAllowed'][number]): void {
    const { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, selectedOption.key);

    if (this.#shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }

  @action
  onFocusChange(isFocused: boolean): void {
    const { onFocusIn, onFocusOut, field } = this.args;
    return isFocused ? onFocusIn(field.key) : onFocusOut(field.key);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::RadioGroup': typeof TransfersInternationalOutDynamicFormFieldRadioGroupComponent;
  }
}
