export default {
  "modal": "qkB",
  "section": "qkE",
  "header": "qkd",
  "title": "qkI",
  "icon": "qkt",
  "content": "qkM",
  "export-template-wrapper": "qkP",
  "export-template-radio": "qkl",
  "export-template-content": "qkX",
  "export-template-label": "qkC",
  "export-template-button": "qkk",
  "export-template-list": "qkJ",
  "inactive": "qks",
  "export-template-title": "qkF",
  "export-template-description": "qkg",
  "export-template-item-content": "qkD",
  "permission-disclaimer-container": "qkV",
  "export-template-footer": "qkL",
  "export-template-cta-container": "qkT",
  "upsell": "qkz",
  "upsell-section": "qka",
  "_content": "qkH",
  "_cta": "qkO",
  "upsell-title": "qkm",
  "upsell-description": "qkp"
};
