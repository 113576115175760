export default {
  "transaction": "qJT",
  "transaction-details": "qJz",
  "transaction-details-amount": "qJa body-1",
  "credit": "qJH",
  "negative-amount": "qJO",
  "transaction-details-description": "qJm body-2",
  "transaction-remove-cta": "qJp",
  "avatar": "qJx mr-16",
  "spinner": "qJw"
};
