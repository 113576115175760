export default {
  "card-header": "qFB",
  "counterparty-name": "qFE",
  "pan-section": "qFd header-section body-2",
  "infobox": "qFI header-section",
  "see-card-button": "qFt",
  "card-identifier-section": "qFM",
  "card-icon": "qFP",
  "nickname": "qFl body-1",
  "pan": "qFX body-2"
};
