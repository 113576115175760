export default {
  "wrapper": "qCH",
  "section-wrapper": "qCO",
  "header": "qCm",
  "title": "qCp body-1",
  "close-icon": "qCx",
  "section": "qCw",
  "section-title": "qCN caption-bold",
  "labels": "qCv",
  "labels-title": "qCo",
  "section-checkbox": "qCn",
  "grow": "qCi scroll",
  "label": "qCA",
  "buttons": "qCY"
};
