export default {
  "custom-export-loading": "qkn",
  "title": "qki",
  "description": "qkA",
  "section": "qkY",
  "placeholder-avatar": "qkh",
  "_with-avatar": "qkf",
  "_with-list": "qkK",
  "list-item": "qkG",
  "radio": "qkr",
  "header": "qkb",
  "header-line": "qky"
};
