export default {
  "display-block": "qas",
  "pay-later-tooltip": "qaF",
  "disabled": "qag",
  "stepper": "qaD",
  "installments": "qaV",
  "flex-items": "qaL",
  "day-label": "qaT body-2",
  "installment-amount": "qaz body-1",
  "spacing": "qaa",
  "info-icon": "qaH",
  "detail": "qaO",
  "detail-value": "qam",
  "extra": "qap",
  "extra-value": "qax",
  "badge": "qaw",
  "title": "qaN",
  "link": "qav"
};
