import { template as template_60a2299523ad4519830bf7ccbf22b0d1 } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import perform from 'ember-concurrency/helpers/perform';
import { t } from 'ember-intl';
import eq from 'ember-truth-helpers/helpers/eq';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import StickyPanel from 'qonto/components/sticky-panel';
import { STATUS as REQUEST_STATUS } from 'qonto/constants/requests';
import { RequestSuccess } from 'qonto/react/components/transfers/sepa/settlement';
import styles from './styles.strict-module.css';
export default class TransfersSepaSettlementRequestTransfer extends Component {
    @service
    segment;
    @service
    router;
    @service
    organizationManager;
    @service
    payByInvoiceUploadManager;
    RequestSuccessComponent = RequestSuccess;
    get requestTransfer() {
        return this.args.requestTransfer;
    }
    static{
        template_60a2299523ad4519830bf7ccbf22b0d1(`
    <ReactBridgeWithProviders @reactComponent={{this.RequestSuccessComponent}} />

    <StickyPanel class={{styles.sticky-panel}}>
      {{#if (eq this.requestTransfer.status REQUEST_STATUS.PENDING)}}
        <button
          class='btn btn--primary'
          type='button'
          data-test-sepa-transfer-request-details-button
          {{on
            'click'
            (perform
              @onClickButtonPrimary 'see-request-details' (hash transfer=this.requestTransfer)
            )
          }}
        >
          {{t 'requests.transfers.modals.requester.success.btn'}}
        </button>
      {{/if}}

      <button
        class='btn btn--tertiary'
        type='button'
        data-test-sepa-transfer-close-button
        {{on 'click' (fn @onClickButtonSecondary 'close')}}
      >
        {{t 'btn.close'}}
      </button>
    </StickyPanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
