export default {
  "vat-lines": "qOe",
  "vat-line": "qOB",
  "vat-input": "qOE",
  "vat-rate": "qOd",
  "vat-option-button": "qOI",
  "disabled": "qOt",
  "vat-option-tooltip": "qOM",
  "remove-label": "qOP",
  "remove-button": "qOl",
  "vat-total": "qOX",
  "vat-total-amount": "qOC",
  "add-vat-line": "qOk",
  "with-vat-lines": "qOJ"
};
