export default {
  "container": "qaj",
  "container-column": "qaW",
  "content-wrapper": "qau",
  "media-wrapper": "qHS",
  "content": "qHc",
  "title": "qHq",
  "subtitle": "qHZ",
  "content-slot": "qHR",
  "sticky-panel": "qHQ",
  "sticky-actions": "qHe"
};
