/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { Flag } from '@repo/design-system-kit';

import type {
  FormFieldArgs,
  FormFieldWithOptions,
} from 'qonto/components/transfers/international-out/dynamic-form/field/arguments';
import { TRIGGER_SELECT_COMPONENTS } from 'qonto/constants/dynamic-form';
import isFunction from 'qonto/utils/is-function';

export const MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH = 5;

interface Signature {
  Args: FormFieldArgs<FormFieldWithOptions> & {
    onClose: (key: FormFieldWithOptions['key']) => void;
  };
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutDynamicFormFieldSelectComponent extends Component<Signature> {
  flag = Flag;

  get hasFlagIcon(): boolean {
    return this.args.field.triggerComponent === TRIGGER_SELECT_COMPONENTS.COUNTRY;
  }

  get isSearchEnabled(): boolean {
    return this.options.length > MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH;
  }

  @cached
  get options(): FormFieldWithOptions['valuesAllowed'] {
    return (
      this.args.field.valuesAllowed?.map(({ key, name, ...rest }) => ({
        ...rest,
        key,
        name,
        searchableKeywords: [key, name],
      })) || []
    );
  }

  get selectedOption(): FormFieldWithOptions['valuesAllowed'][number] | null {
    return this.options.find(option => option.key === this.args.value) ?? null;
  }

  get shouldRefreshOnChange(): boolean {
    const { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onSelect(selectedOption: FormFieldWithOptions['valuesAllowed'][number]): void {
    const { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, selectedOption.key);

    if (this.shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Select': typeof TransfersInternationalOutDynamicFormFieldSelectComponent;
  }
}
