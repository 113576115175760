export default {
  "transfer-header": "qgB",
  "infobox": "qgE header-section",
  "proof-section": "qgd",
  "proof-link": "qgI",
  "download-icon": "qgt",
  "cancel-button": "qgM",
  "request-refund-button": "qgP",
  "refund-requested-indicator": "qgl",
  "recall-indicator": "qgX",
  "payment-purpose": "qgC",
  "payment-purpose-label": "qgk",
  "repeat-infobox": "qgJ qgE header-section",
  "additional-infobox": "qgs qgE header-section",
  "repeat-transfer-action": "qgF",
  "financing-not-compliant-disclaimer": "qgg"
};
