export default {
  "fileupload": "qHM",
  "file": "qHP",
  "label": "qHl",
  "zone": "qHX",
  "error": "qHC",
  "hide": "qHk",
  "dropzone": "qHJ",
  "hidden": "qHs",
  "upload-icon": "qHF",
  "dropping": "qHg",
  "bounce2": "qHD",
  "error-message": "qHV"
};
