export default {
  "wrapper": "qVc",
  "ember-power-select-dropdown": "qVq",
  "status": "qVZ body-1",
  "error": "qVR",
  "success": "qVQ",
  "type-container": "qVe",
  "type-close": "qVB",
  "date": "qVE",
  "close-icon": "qVd",
  "container": "qVI",
  "container-picto": "qVt",
  "avatar": "qVM",
  "container-picto-status": "qVP",
  "general": "qVl",
  "general-amount": "qVX",
  "unprocessed": "qVC",
  "fx-rate": "qVk",
  "general-counterparty": "qVJ body-2",
  "infobox": "qVs",
  "account-infobox": "qVF qVs",
  "initiator-infobox": "qVg qVs",
  "transaction-infobox": "qVD qVs",
  "repeat-infobox": "qVV qVs",
  "item": "qVL body-2",
  "eye-icon": "qVT",
  "repeat-transfer-action": "qVz",
  "disclaimer": "qVa",
  "start-verification-link": "qVH",
  "financing-not-compliant-disclaimer": "qVO"
};
